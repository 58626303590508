<template>
  <section class="case-creation__step case-creation__patient-information">
    <div class="case-creation__card">
      <div class="case-creation__heading">
        <div class="title-with-icon">
          <span class="icon">
            <img
              src="@/assets/images/icons/icon-patient-info.svg"
              alt="icon"
              class="img-fluid"
            />
          </span>
          <h3 class="title">Create Accession</h3>
        </div>
      </div>
      <div class="case-creation__body">
        <div class="vue-form">
          <el-form :model="caseInformationFormData">
            <el-row :gutter="15">
              <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
                <div class="case-creation__input">
                  <el-form-item class="mb-0">
                    <label for="text">
                      Laboratory
                      <span class="star">*</span>
                    </label>
                    <el-select
                      v-model="caseInformationFormData.lab"
                      placeholder="Please Select Lab"
                    >
                      <el-option
                        v-for="(lab, index) of getAllLabs"
                        :key="index"
                        :value="lab._id"
                        :label="lab.name"
                      ></el-option>
                    </el-select>
                    <p
                      class="error"
                      v-if="
                        getAccessionCreationValidationErrors &&
                        getAccessionCreationValidationErrors.lab
                      "
                    >
                      {{ getAccessionCreationValidationErrors.lab }}
                    </p>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
                <div class="case-creation__input">
                  <el-form-item class="mb-0">
                    <label for="text">Specimen Collector</label>
                    <el-input
                      v-model="caseInformationFormData.specimen_collector"
                    ></el-input>
                    <p
                      class="error"
                      v-if="
                        getAccessionCreationValidationErrors &&
                        getAccessionCreationValidationErrors.specimen_collector
                      "
                    >
                      {{
                        getAccessionCreationValidationErrors.specimen_collector
                      }}
                    </p>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
                <div class="case-creation__input">
                  <el-form-item class="mb-0">
                    <label for="text">
                      Collection Date & Time
                      <span class="star">*</span>
                    </label>
                    <el-date-picker
                      type="datetime"
                      v-model="caseInformationFormData.collection_date"
                      placeholder="MM-DD-YYYY HH:MM AM"
                      format="MM-DD-YYYY hh:mm A"
                      @focus="checkDateValue"
                      :disabled-date="disabledDate"
                      :default-time="defaultTime"
                    ></el-date-picker>

                    <p
                      class="error"
                      v-if="
                        getAccessionCreationValidationErrors &&
                        getAccessionCreationValidationErrors.collection_date
                      "
                    >
                      {{ getAccessionCreationValidationErrors.collection_date }}
                    </p>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
                <div class="case-creation__input">
                  <el-form-item class="mb-0">
                    <label for="text">
                      Received Date & Time
                      <span class="star">*</span>
                    </label>
                    <el-date-picker
                      type="datetime"
                      v-model="caseInformationFormData.received_date"
                      placeholder="MM-DD-YYYY HH:MM AM"
                      format="MM-DD-YYYY hh:mm A"
                      @focus="checkDateValue"
                      :disabled-date="disabledDate"
                      :default-time="defaultTime"
                    ></el-date-picker>

                    <p
                      class="err error-break"
                      v-if="
                        getAccessionCreationValidationErrors &&
                        getAccessionCreationValidationErrors.received_date
                      "
                    >
                      {{ getAccessionCreationValidationErrors.received_date }}
                    </p>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
                <div
                  class="case-creation__input"
                  v-if="
                    this.getCaseInformation &&
                    this.getCaseInformation.case_types &&
                    this.getCaseInformation.case_types.length
                  "
                >
                  <el-form-item class="mb-0">
                    <label for="text">
                      Case Type
                      <span class="star">*</span>
                    </label>
                    <el-select
                      v-model="caseInformationFormData.caseTypes"
                      placeholder="Please Select Case Type"
                      multiple
                      :multiple-limit="getCaseTypeLimit"
                    >
                      <el-option
                        v-for="(
                          caseType, index
                        ) of getCaseInformation.case_types"
                        :key="index"
                        :value="caseType"
                        :label="caseTypeMap[caseType]"
                        :disabled="checkCaseTypeDisable(caseType)"
                      ></el-option>
                    </el-select>
                    <p
                      class="err"
                      v-if="
                        getAccessionCreationValidationErrors &&
                        getAccessionCreationValidationErrors.case_types
                      "
                    >
                      {{ getAccessionCreationValidationErrors.case_types }}
                    </p>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
                <div class="case-creation__input">
                  <el-form-item class="mb-0">
                    <label for="text">Marketer</label>
                    <el-input
                      type="text"
                      placeholder="Enter Marketer Details"
                      v-model="caseInformationFormData.marketer_details"
                      :rows="4"
                    ></el-input>
                    <p
                      class="err"
                      v-if="
                        getAccessionCreationValidationErrors &&
                        getAccessionCreationValidationErrors.marketer_details
                      "
                    >
                      {{
                        getAccessionCreationValidationErrors.marketer_details
                      }}
                    </p>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xl="24" :lg="24" :md="8" :sm="12" :xs="12">
                <div
                  class="case-creation__actions"
                  v-if="hasPermission('orders', 'create_accession')"
                >
                  <ul class="list">
                    <li>
                      <el-button
                        class="btn cancel-button"
                        type="button"
                        @click="cancelNewCase"
                        >Cancel</el-button
                      >
                    </li>
                    <li>
                      <el-button
                        class="btn save-button"
                        type="button"
                        @click="sumbitCaseInformation"
                        :loading="loading"
                        >Create Accession</el-button
                      >
                    </li>
                  </ul>
                </div>
              </el-col>
            </el-row>
            <el-row
              v-if="
                getAccessionCreationValidationErrors &&
                getAccessionCreationValidationErrors.critical_error
              "
            >
              <el-col :span="12">
                <p class="error">
                  * {{ getAccessionCreationValidationErrors.critical_error }}
                </p>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </div>
    <ExistingCaseDetails
      v-if="errorDiloag"
      :convert="convertToCase"
      :existedCase="existedCaseDetails"
      :existedOrder="exisistingOrderData"
      v-on:closePopupDialog="handlePopUp"
      :caseType="caseInformationFormData.caseTypes"
      @approveCase="approveCase"
      :validationErrorMsg="validationError"
    >
    </ExistingCaseDetails>
    <BillingValidation
      v-if="icdErrorDialog"
      :validationErrors="billingErrors"
      v-on:close="icdErrorDialog = false"
      :convert="convertToCase"
    ></BillingValidation>
  </section>
</template>
<script>
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";
import AppHelper from "@/mixins/AppHelper";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
const ExistingCaseDetails = defineAsyncComponent(() =>
  import("@/components/cases/ExisistedCaseInfo")
);
import caseConstants from "@/config/constants/caseConstants";
const BillingValidation = defineAsyncComponent(() =>
  import("@/components/cases/BillingValidation")
);
export default {
  components: {
    ExistingCaseDetails,
    BillingValidation,
  },

  created() {},
  mixins: [AppHelper, UserPermissionsHelper],
  async mounted() {
    await this.$store.commit(
      "orders/setAccessionCreationValidationErrors",
      {},
      {
        root: true,
      }
    );
    await this.getCaseData();
    await this.$store.dispatch("labs/fetchAllLabs", { get_all: true });
    await this.$store.commit("errors/clear", null, { root: true });

    if (this.getUserPrimaryLabId) {
      this.caseInformationFormData.lab = this.getUserPrimaryLabId;
    }
  },
  computed: {
    ...mapGetters("labs", ["getAllLabs"]),
    ...mapGetters("auth", ["getAuthenticatedUser", "getUserPrimaryLabId"]),
    ...mapGetters("orders", [
      "getCaseInformation",
      "getAccessionCreationValidationErrors",
      "getAccessionCreateStatus",
      "getAccessionCreationValidationErrorCode",
    ]),
    getCaseTypeLimit() {
      return 1;
    },
    CaseTypeDisable() {
      if (
        this.getCaseInformation &&
        this.getCaseInformation.accession_data &&
        this.getCaseInformation.accession_data.length
      ) {
        return this.getCaseInformation.accession_data.map(
          (obj) => obj.case_type
        );
      }
      return [];
    },
  },
  data() {
    return {
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      defaultTime: [new Date()],
      errorDiloag: false,
      convertToCase: true,
      existedCaseDetails: {},
      exisistingOrderData: {},
      caseInformationFormData: {
        lab: "",
        collection_date: "",
        received_date: new Date(),
        caseTypes: [],
      },
      allLabs: [],
      loading: false,
      panelsTestsData: {
        selectedTestIds: [],
        selectedCoriellPanels: [],
      },
      validationError: "",
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
      testInfo: [],
      specimenTypes: [],
      coriell_test_panels: [],
      medicalNecessities: [],
      icdErrorDialog: false,
      billingErrors: [],
    };
  },
  methods: {
    checkCaseTypeDisable(value) {
      return this.CaseTypeDisable.includes(value);
    },
    async handlePopUp(response) {
      this.errorDiloag = response;
      await this.$store.commit("errors/clear", null, { root: true });
    },
    async getCaseData() {
      const orderId = this.$route.params.order_id;
      if (orderId) {
        await this.$store.dispatch("orders/fetchCaseInformation", {
          order_id: orderId,
        });
        if (this.getCaseInformation && this.getCaseInformation.collection_date) {
          this.caseInformationFormData.collection_date =
            this.convertLabUTCToClient(this.getCaseInformation.collection_date);
        }
        if (this.getCaseInformation && this.getCaseInformation.tests_info) {
          this.panelsTestsData.selectedTestIds =
            this.getCaseInformation.tests_info.map((el) => el._id);
        }
        if (
          this.getCaseInformation &&
          this.getCaseInformation.coriell_test_panels &&
          this.getCaseInformation.coriell_test_panels.length
        ) {
          this.panelsTestsData.selectedCoriellPanels =
            this.getCaseInformation.coriell_test_panels;
        }
        this.prepreCaseDataFromFormData();
        if (
          this.getCaseInformation &&
          this.getCaseInformation.case_types &&
          this.getCaseInformation.case_types.length == 1
        ) {
          this.caseInformationFormData.caseTypes =
            this.getCaseInformation.case_types;
        }
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    getObjectByCaseType(testArray, caseType) {
      for (let i = 0; i < testArray.length; i++) {
        if (testArray[i].case_type === caseType) {
          return testArray[i];
        }
      }
      return [];
    },
    prepareTestInfoData() {
      if (
        this.caseInformationFormData.caseTypes &&
        this.getCaseInformation.coriell_test_panels
      ) {
        this.coriell_test_panels.push(
          this.getObjectByCaseType(
            this.getCaseInformation.coriell_test_panels,
            this.caseInformationFormData.caseTypes[0]
          )
        );
      }
      if (
        (this.caseInformationFormData.caseTypes &&
          this.getCaseInformation.medical_necessities &&
        this.getCaseInformation.medical_necessities.length)
      ) {
        if (
          this.getObjectByCaseType(
            this.getCaseInformation.medical_necessities,
            this.caseInformationFormData.caseTypes[0]
          ).length
        ) {
          this.medicalNecessities.push(
            this.getObjectByCaseType(
              this.getCaseInformation.medical_necessities,
              this.caseInformationFormData.caseTypes[0]
            )
          );
        }
      }
      if (
        this.getCaseInformation &&
        this.getCaseInformation.sample_types &&
        this.caseInformationFormData.caseTypes
      ) {
        const selectedSpecimen = this.getCaseInformation.sample_types.filter(
          (caseObj) =>
            caseObj.case_type === this.caseInformationFormData.caseTypes[0]
        );
        this.specimenTypes = selectedSpecimen;
      }
    },
    prepreCaseDataFromFormData() {
      let params = {
        case_types: this.caseInformationFormData.caseTypes,
        icd_10_codes: this.getCaseInformation.icd_10_codes,
        status: this.getCaseInformation.status,
        billing_status: this.getCaseInformation.billing_status,
        patient_order: this.getCaseInformation.patient_order,
        lab: this.getUserPrimaryLabId,
        collection_date: this.convertToLabUTC(
          this.caseInformationFormData.collection_date
        ),
        received_date: this.convertToLabUTC(
          this.caseInformationFormData.received_date
        ),
        hospital: this.getCaseInformation.hospital
          ? this.getCaseInformation.hospital._id
          : null,
        ordering_physician: this.getCaseInformation.ordering_physician
          ? this.getCaseInformation.ordering_physician._id
          : null,
        referring_physician: this.getCaseInformation.referring_physician
          ? this.getCaseInformation.referring_physician._id
          : null,
        hospital_branch: this.getCaseInformation.hospital_branch
          ? this.getCaseInformation.hospital_branch._id
          : null,
        billing_type: this.getCaseInformation.billing_type,
        specimen_collector: this.getCaseInformation.specimen_collector,
        requisition_order_id: this.getCaseInformation.requisition_order_id,
        patient_info: { ...this.getCaseInformation.patient_info },
        covid_status: this.getCaseInformation.covid_status,
        test_results: this.getCaseInformation.test_results,
        test_reports: this.getCaseInformation.test_reports,
        attachments: this.getCaseInformation.attachments,
        internal_documents: this.getCaseInformation.internal_documents,
        internal_notes: this.getCaseInformation.internal_notes,
        coriell_test_panels:
          this.coriell_test_panels && this.coriell_test_panels.length
            ? this.coriell_test_panels
            : this.getCaseInformation.coriell_test_panels,
        specimen_types: this.getCaseInformation.specimen_types,
        billing_info: this.getCaseInformation.billing_info
          ? { ...this.getCaseInformation.billing_info }
          : undefined,
        sample_types:
          this.specimenTypes && this.specimenTypes.length
            ? this.specimenTypes
            : this.getCaseInformation.sample_types,
        toxicology_order_medical_necessity_fields:
          this.getCaseInformation.toxicology_order_medical_necessity_fields,
        urinalysis_order_medical_necessity_fields:
          this.getCaseInformation.urinalysis_order_medical_necessity_fields,
        urinary_tract_infectious_medical_necessity_fields:
          this.getCaseInformation
            .urinary_tract_infectious_medical_necessity_fields,

        signature_settings: this.getCaseInformation.signature_settings,
        order: this.$route.params.order_id,
        order_id: this.getCaseInformation.order_id,
      };
      if (
        this.getCaseInformation.medication &&
        this.getCaseInformation.medication.length
      ) {
        params.medication = this.getCaseInformation.medication;
      } else {
        params.medication = [];
      }

      if (
        this.caseInformationFormData.caseTypes &&
        this.caseInformationFormData.caseTypes.length
      ) {
        const casetypes = [
          "BLOOD_PANEL",
          "CGX_PANEL",
          "PULMONARY_PANEL",
          "PAD_ALZHEIMERS",
          "DIABETES",
          "CARDIAC",
          "PGX_TEST",
        ];
        const isPanelPresent = casetypes.includes(
          this.caseInformationFormData.caseTypes[0]
        );

        if (isPanelPresent) {
          params.medical_necessities = [];
        } else {
          params.medical_necessities =
            this.medicalNecessities && this.medicalNecessities.length
              ? this.medicalNecessities
              : this.getCaseInformation.medical_necessities;
        }
      }
      if (
        this.caseInformationFormData.caseTypes &&
        this.getCaseInformation.tests_info
      ) {
        let caseType = this.caseInformationFormData.caseTypes[0];
        const selectedCaseTestInfo = this.getCaseInformation.tests_info.filter(
          (caseObj) => caseObj.case_type_code === caseType
        );
        if (selectedCaseTestInfo && selectedCaseTestInfo.length) {
          params.tests_info = selectedCaseTestInfo.map((test) => test._id);
        } else {
          params.tests_info = [];
        }
      }
      if (
        this.getCaseInformation &&
        this.getCaseInformation.toxicology_ereq_form &&
        this.getCaseInformation.toxicology_ereq_form.length
      ) {
        params.toxicology_ereq_form =
          this.getCaseInformation.toxicology_ereq_form;
      }
      return params;
    },
    async fetchAllLabs() {
      this.allLabs = this.getAllLabs;
    },
    async approveCase(response) {
      this.errorDiloag = response;
      await this.$store.commit("errors/clear");
      this.loading = true;
      const orderId = this.$route.params.order_id;
      let params = this.prepreDataFromFormData();
      params.order = orderId;
      params.auto_approved = true;
      await this.$store.dispatch("orders/createAccessionForOrder", params);
      this.handleResponse();
      this.loading = false;
    },
    async sumbitCaseInformation() {
      try {
        this.loading = true;
        const orderId = this.$route.params.order_id;
        let params = this.prepreDataFromFormData();
        params.order = orderId;
        await this.$store.dispatch("orders/createAccessionForOrder", params);
        this.loading = false;
        this.handleResponse();
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: "Erorr while creating acccession",
        });
      }
    },
    handleResponse() {
      if (this.getAccessionCreateStatus) {
        this.cancelNewCase();
      } else if (
        this.getAccessionCreationValidationErrorCode.error_code ===
          "GENETIC_TEST_EXISTED" ||
        this.getAccessionCreationValidationErrorCode.error_code ===
          "TEST_ALREADY_EXISTED" ||
        this.getAccessionCreationValidationErrorCode.error_code ===
          "NON_GENTIC_TEST_VALIDATE" ||
        this.getAccessionCreationValidationErrorCode.error_code ===
          "DUPLICATE_EXISTED"
      ) {
        this.errorDiloag = true;
        this.validationError =
          this.getAccessionCreationValidationErrorCode.error_code;
        if (
          this.getCaseInformation &&
          this.getCaseInformation.case_types &&
          this.getCaseInformation.case_types.length > 1
        ) {
          this.prepareTestInfoData();
        }
        if (
          this.getAccessionCreationValidationErrorCode.errors &&
          this.getAccessionCreationValidationErrorCode.errors.existed_details
        )
          this.existedCaseDetails =
            this.getAccessionCreationValidationErrorCode.errors.existed_details;

        this.exisistingOrderData = this.prepreCaseDataFromFormData();
        if (
          this.getCaseInformation &&
          this.getCaseInformation.physician_notes
        ) {
          this.exisistingOrderData.physician_notes =
            this.getCaseInformation.physician_notes;
        }
      } else if (
        this.getAccessionCreationValidationErrorCode.error_code ===
        "BILLING_VALIDATION_ERRORS"
      ) {
        this.icdErrorDialog = true;
        this.billingErrors =
          this.getAccessionCreationValidationErrorCode.errors;
      } else if (!this.getAccessionCreationValidationErrors) {
        this.$notify.error({
          title: "Error",
          message: "Erorr while creating acccession",
        });
      }
    },
    async cancelNewCase() {
      this.$router.push({
        path: "all-orders/active",
        name: "ActiveOrders",
        query: this.$route.query,
      });
    },

    prepreDataFromFormData() {
      return {
        lab: this.caseInformationFormData.lab,
        collection_date: this.convertToLabUTC(
          this.caseInformationFormData.collection_date
        ),
        received_date: this.convertToLabUTC(
          this.caseInformationFormData.received_date
        ),
        marketer_details: this.caseInformationFormData.marketer_details
          ? this.caseInformationFormData.marketer_details
          : undefined,
        specimen_collector: this.caseInformationFormData.specimen_collector
          ? this.caseInformationFormData.specimen_collector
          : undefined,
        case_types: this.caseInformationFormData.caseTypes
          ? this.caseInformationFormData.caseTypes
          : undefined,
      };
    },
    goToAllCases() {
      this.$router.push({ path: "/all-cases", name: "AllCases" });
    },
  },
};
</script>
<style lang="scss" scoped>
.error {
  margin-top: 2px !important;
  color: #eb5757;
  font-size: 12px;
  min-height: 20px;
  white-space: nowrap;
  margin-bottom: -30px;
  line-height: 20px;
  font-weight: 500;
  padding-left: 2px;
}
</style>
